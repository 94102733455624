import { trackingEvent } from '../../../../analytics';
import { AMPLITUDE_SERVICE } from '../../../../analytics/constants';
import { getBasicAmplitudEventProperties } from '../../../../analytics/utils';

const recursiveTasksHide = (task, taskArrayValidate, filterExist) => {
  if (task.hide) {
    filterExist.push(true);
  } else {
    taskArrayValidate.push(task.id);
  }
  if (task.children.length > 0) {
    task.children.forEach((task) => {
      recursiveTasksHide(task, taskArrayValidate, filterExist);
    });
  }
};

export const getVisibleTaskIds = (activities) => {
  const taskArrayValidate = [];
  const filterExist = [];
  activities.forEach((activity) => {
    activity.tasks.forEach((task) => {
      recursiveTasksHide(task, taskArrayValidate, filterExist);
    });
  });
  return taskArrayValidate;
};

export const trackLookaheadPdfExport = (eventResult, trackEventProps) => {
  trackingEvent(
    'lookahead_pdf_exportable_generation',
    {
      ...getBasicAmplitudEventProperties(),
      start_date_selected: trackEventProps.startDate,
      weeks_number_selected: trackEventProps.weeksNumber,
      event_result: eventResult
    },
    AMPLITUDE_SERVICE
  );
};

export const navigateToExportRoute = ({
  history,
  formattedDate,
  colorSchema,
  numWeeks
}) => {
  const url = `/reportlookahead/week/all/${formattedDate}/${colorSchema}/${numWeeks}`;
  history.push(url);
};
